import React from "react";

// componenents
import Layout from "../components/Layout";
import ForgotYourPasswordForm from "../components/UserSystem/ForgotYourPasswordForm"
import { useAxiosCall } from '../hooks/useAxiosCall'
import { messageService } from '../services/message.service'

// markup
const ForgotYourPassword = () => {
  const axiosCall = useAxiosCall()
  const onFormSubmit = (emailAddress) => {
    // send to axios

    const url = 'https://dev-tuckel.pantheonsite.io/user/password?_format=json'
    const method = 'post'
    const payload = {
        mail: emailAddress
    }
    
    const onSuccess = (response) => {
      // notify the user of success via the alert toaster
      messageService.sendMessageAutoClose('A password reset has been sent to your email address.', 'success');

      console.log('[ForgotYourPassword] success: ', response)
    }

    const onFailure = (e) => {
      console.log('[ForgotYourPassword] error: ', e.response)
      const msg = e.response.data.message
      messageService.sendMessageAutoClose(msg, 'error');
    }
    axiosCall (url, method, JSON.stringify(payload), onSuccess, onFailure)

  }
  // onFormSubmit()

  return (
    <Layout>
      <ForgotYourPasswordForm onFormSubmit={onFormSubmit}/>
    </Layout>
  )
};

export default ForgotYourPassword;