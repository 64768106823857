import React, { useState, useContext }  from "react";

import { FormErrors, FormCheckbox } from "../LoginForm/styles"

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import { Link } from "gatsby"
import UserSystemNavigation from "../Navigation";

// import { CurrentUserContext } from "../../../context/CurrentUserProvider"

// display a gatsby fluid image
const ForgotYourPasswordForm = ({ onFormSubmit }) => {
  // const { processUserResponseData } = useContext(CurrentUserContext)
    // dummy values (if in dev mode)
  // const devMode = process.env.GATSBY_DEVMODE

  const emailInitial = '';
  // const emailInitial = (devMode) ? 'bettye.fritsch@corwin.com' : '';

  const [email, setEmail] = useState(emailInitial);
  const [emailWarning, setEmailWarning] = useState("");
  
  const [errorMsg, setErrorMsg] = useState("");

  function validateForm() {
    return email.length > 0;
  }

  async function HandleSubmit(event) {
    event.preventDefault();
    if (!email.length > 0) {
      setEmailWarning("Error: email address cannot be empty");
    } else {
      onFormSubmit(email)
    }
    
    return true
    
/*
    const getForgotYourPasswordAPIURL = `http://localhost:8001/forgot_your_password`
    const formData = new FormData();
    
    // todo: send via json, instead of: multipart/form-data
    // formData.append('email', email);
    // payload
    console.log('calling (#sf3F2): ' + getForgotYourPasswordAPIURL)
    const payload = {
      'email': email
    };
    
    const response = await axios.post(getForgotYourPasswordAPIURL, payload, {
      headers: {
        'Content-Type': 'application/json'  // 'multipart/form-data'
      },
      mode: 'no-cors',
      withCredentials: true,
      credentials: 'same-origin'
    }).then((response) => {
      alert('success')
      // zzz: uncomment this
      // processUserResponseData(data.userData)
    }).catch(() => {
      alert('error')
    });
    
    // axios.defaults.withCredentials = true
    // const response = await axios({
    //   method: 'post',
    //   url: getForgotYourPasswordAPIURL,
    //   // data: {
    //   //   email: email,
    //   //   password: password
    //   // }
    //   data: formData
    // });
    const data = response.data

    console.log('axios reponse (#wcwc3): ', data)
    */

  }

  return (
    <>
        <div className="Login">
        {errorMsg && (
          <FormErrors>
            {errorMsg}
          </FormErrors>
          )
        }
        
        {/* <Container>
          <Row>
            <Col xs={3} md={3} lg={3}>

            </Col>
          </Row>
        </Container> */}
            
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'600px'}}>
            <h2>Forgot your password?</h2>
            <UserSystemNavigation currentRoute="/ForgotYourPassword" />
            Enter your email and we'll email you a password reset link to recover your account.
            
            {/* <br /><br />To create a new account, click here: signup */}
            <br /><br />
            <Form onSubmit={HandleSubmit}>
              <Form.Group size="lg" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div>
                  {emailWarning}
                </div>
              </Form.Group>

              <Button block size="lg" type="submit" disabled={!validateForm()}>
                Send password reset email
              </Button>
            </Form>
          </div>
        
        </div>
      </div>
    </>
  );
    
}

export default ForgotYourPasswordForm